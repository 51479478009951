<script lang="ts" setup></script>

<template>
	<div class="levitation">
		<div class="levitation__shapes">
			<div class="levitation__object levitation__tube2"></div>
			<div class="levitation__object levitation__triangle"></div>
			<div class="levitation__object levitation__tube1"></div>
			<div class="levitation__object levitation__cube1"></div>
			<div class="levitation__object levitation__lightning1"></div>
			<div class="levitation__object levitation__ring2"></div>
			<div class="levitation__object levitation__cube2"></div>
			<div class="levitation__object levitation__sphere1"></div>
			<div class="levitation__object levitation__lightning2"></div>
			<div class="levitation__object levitation__sphere2"></div>
			<div class="levitation__object levitation__sphere3"></div>
			<div class="levitation__object levitation__sphere4"></div>
			<div class="levitation__object levitation__ring1"></div>
		</div>
		<div class="levitation__object levitation__alien"></div>
		<div class="levitation__object levitation__shadow"></div>
		<div class="levitation__object levitation__shadow-accent"></div>
	</div>
</template>

<style lang="scss">
@import "assets/styles/variables/variables";

.levitation {
	position:  relative;
	width:     480px;
	min-width: 300px;
	height:    0;

	&__shapes {
		opacity: 0.5;
	}

	&__object {
		position: absolute;
	}

	&__shadow {
		width:      346px;
		height:     7px;
		top:        425px;
		right:      -15px;
		background: #656565;
		opacity:    0.16;
		filter:     blur(16px);
	}

	&__shadow-accent {
		width:      122px;
		height:     7px;
		top:        425px;
		right:      80px;
		background: #6b6b6b;
		background: radial-gradient(circle, #000, #6b6b6b);
		opacity:    0.24;
		filter:     blur(9px);
		animation:  shadow-increase 2s linear infinite alternate;
	}

	&__alien {
		margin-top: 120px;
		right:      10px;
		height:     272px;
		width:      270px;
		background: url("/images/various/alien.png") center no-repeat;
		animation:  go-alien 2s ease-in-out infinite alternate;
	}

	&__cube1 {
		margin-top: 285px;
		right:      33px;
		height:     51px;
		width:      53px;
		background: url("/images/shapes/cube1.png") center no-repeat;
		animation:  go-cube 4s cubic-bezier(.67, .2, .41, .76) infinite 200ms;
	}

	&__cube2 {
		margin-top: 320px;
		right:      200px;
		height:     49px;
		width:      43px;
		background: url("/images/shapes/cube2.png") center no-repeat;
		animation:  go-reverse 2s cubic-bezier(.67, .2, .41, .76) infinite alternate;
	}

	&__lightning1 {
		margin-top: 135px;
		right:      231px;
		height:     79px;
		width:      101px;
		background: url("/images/shapes/lightning1.png") center no-repeat;
		animation:  go-lightning 2s cubic-bezier(.65, .05, .36, 1) infinite alternate 400ms;
	}

	&__lightning2 {
		margin-top: 155px;
		right:      3px;
		height:     108px;
		width:      94px;
		background: url("/images/shapes/lightning2.png") center no-repeat;
		animation:  go-lightning-mini 2s ease-in-out infinite alternate 600ms;
	}

	&__ring1 {
		margin-top: 132px;
		right:      85px;
		height:     188px;
		width:      206px;
		background: url("/images/shapes/ring1.png") center no-repeat;
		animation:  go-alien 2s cubic-bezier(.67, .16, .64, .99) infinite alternate 300ms;
	}

	&__ring2 {
		margin-top: 156px;
		right:      0;
		height:     126px;
		width:      80px;
		background: url("/images/shapes/ring2.png") center no-repeat;
		animation:  go-alien 2s ease-in-out infinite alternate 500ms;
	}

	&__sphere1 {
		margin-top: 214px;
		right:      245px;
		height:     81px;
		width:      81px;
		background: url("/images/shapes/sphere1.png") center no-repeat;
		animation:  go-alien 3s infinite alternate;
	}

	&__sphere2 {
		margin-top: 327px;
		right:      247px;
		height:     22px;
		width:      23px;
		background: url("/images/shapes/sphere2.png") center no-repeat;
		animation:  go-reverse-mini 2s infinite alternate 500ms;
	}

	&__sphere3 {
		margin-top: 89px;
		right:      17px;
		height:     28px;
		width:      28px;
		background: url("/images/shapes/sphere3.png") center no-repeat;
		animation:  go-alien 2s ease-in-out infinite alternate 100ms;
	}

	&__sphere4 {
		margin-top: 342px;
		right:      76px;
		height:     39px;
		width:      39px;
		background: url("/images/shapes/sphere4.png") center no-repeat;
		animation:  slow-sphere 2s ease-in-out infinite alternate;
	}

	&__triangle {
		margin-top: 105px;
		right:      163px;
		height:     74px;
		width:      73px;
		background: url("/images/shapes/triangle.png") center no-repeat;
		animation:  go-triangle 2.2s ease-in-out infinite alternate;
	}

	&__tube1 {
		margin-top: 85px;
		right:      63px;
		height:     36px;
		width:      64px;
		background: url("/images/shapes/tube1.png") center no-repeat;
		animation:  go-alien 2s ease-in-out infinite alternate 800ms;
	}

	&__tube2 {
		margin-top: 330px;
		right:      16px;
		height:     39px;
		width:      34px;
		background: url("/images/shapes/tube2.png") center no-repeat;
		animation:  go-tube-up 2s cubic-bezier(.65, .05, .36, 1) infinite alternate;
	}
}

@keyframes shadow-increase {
	from {
		opacity: 0.24;
		width:   122px;
		right:   80px;
	}
	to {
		opacity:       0.3;
		height:        9px;
		width:         142px;
		right:         70px;
		border-radius: 50%;
	}
}

@keyframes go-alien {
	from {
		top: 0;
	}
	to {
		top: 20px;
	}
}

@keyframes slow-sphere {
	from {
		top: 9px;
	}
	to {
		top: 0;
	}
}

@keyframes go-lightning {
	from {
		top: 0;
	}
	to {
		top: 23px;
	}
}

@keyframes go-lightning-mini {
	from {
		top: 0;
	}
	to {
		top: 10px;
	}
}

@keyframes go-cube {
	from, to {
		top: 0;
	}

	50% {
		top: 25px;
	}
}

@keyframes go-reverse {
	from {
		top: 20px;
	}
	to {
		top: 0;
	}
}

@keyframes go-reverse-mini {
	from {
		top: 15px;
	}
	to {
		top: 0;
	}
}

@keyframes go-triangle {
	from {
		top: 0;
	}

	to {
		top: 15px;
	}
}

@keyframes go-tube-up {
	from {
		top: 20px;
	}

	to {
		top: 0;
	}
}

@media (max-width: $container-tablet) {
	.levitation {
		display: none;
	}
}
</style>
